import styled from "styled-components"
import Flex from "@components/elements/flex"
import { cssConfig } from "@utils/css-validator"

export const ResourceWrapper = styled(Flex).withConfig(cssConfig)`
  h2:not(:first-child) {
    margin-top: 2rem;
  }

  h3:not(:first-child) {
    margin-top: 1rem;
  }
`
