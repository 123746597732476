import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Html from "@components/html"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { ResourceGrid } from "@components/resources/resource-grid"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { useMemo } from "react"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const KpiExampleCategory = ({ data, pageContext }) => {
  const category = data.category
  const image = category.image
  const imageUrl = image?.cdn
  const examples = useMemo(() => {
    if (category.featured_kpi_example?.length > 0) {
      return category.featured_kpi_example
    } else {
      return category.kpi_examples
        .filter(node => node.status === "published")
        .slice(0, 9)
    }
  }, [category])

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      canonical={pageContext.slug}
      title={category.title}
      description={category.summary}
      seo={category.seo}
      body={category.body}
      date_updated={category.date_updated}
    >
      <article>
        <ResourceWrapper
          gap="6rem"
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <ResourceHeader
            name={category.h1 ?? `${category.name} Metrics & KPIs`}
            alt={`${category.name} Metrics & KPI Examples`}
            subtitle={category.subtitle}
            image={image}
            newsletterCta
            ctaCategory={category.name}
            shadow={false}
            kpi
            links={[
              { text: "Resources", url: "/resources" },
              {
                text: "KPI Examples",
                url: "/resources/kpi-examples",
              },
              {
                text: category.name,
              },
            ]}
          />
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                inherit
                parseElements
                html={category.body}
                images={category.body_images}
                cta
              />
            </Flex>
          </Container>
          {/* Enable when content is in CMS
            {category.related_examples && (
              <Container>
                <RelatedResourceGrid
                  title={category.related_title}
                  examples={category.related_examples}
                  kpi
                />
              </Container>
            )}
          */}
          {category.body2 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={category.body2}
                  images={category.body2_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {category.body3 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={category.body3}
                  images={category.body3_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {category.show_related && !category.related_examples && (
            <Container>
              <ResourceGrid
                parentSlug={category.slug}
                kpi
                title={category.name}
                examples={examples}
                related
              />
            </Container>
          )}
        </ResourceWrapper>
        <KlipsEOPCta />
      </article>
    </Layout>
  )
}

KpiExampleCategory.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default KpiExampleCategory

export const pageQuery = graphql`
  query KpiExampleCategoryPageQuery($id: String!) {
    category: kpiExamplesCategories(id: { eq: $id }) {
      kpi_examples {
        id
        title
        subtitle
        slug
        status
        image {
          id
          title
          cdn
          placeholder
        }
      }
      featured_kpi_example {
        id
        title
        subtitle
        slug
        status
        image {
          id
          title
          cdn
          placeholder
        }
      }
      image {
        directus_id
        id
        title
        cdn
        placeholder
      }
      body
      body2
      body3
      body_images {
        cdn
        placeholder
        directus_id
        id
        title
      }
      body2_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      body3_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      name
      id
      title
      date_updated
      h1
      summary
      subtitle
      slug
      status
      seo {
        ...seoData
      }
      show_related
    }
  }
`
