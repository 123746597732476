import React from "react"
import styled from "styled-components"
import media from "@styles/media"
import PropTypes from "prop-types"
import Breadcrumb from "@components/blog/breadcrumb"
import SignUpCTA from "@components/cta/sign-up-cta"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import Container from "@components/layout/container"
import Image from "@components/image"
import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"

const Column = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
`

const DashboardWrapper = styled.div`
  background: ${({ hasCta }) =>
    hasCta
      ? "linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(242 244 246) 73%, rgba(255, 255, 255, 0) 73%);"
      : "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FAFAFF 100%)"};
  width: 100%;
  overflow: hidden;

  ${props =>
    props.shadow &&
    `
    .klipfolio-image-wrapper {
      div {
        box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.15);
      }
    }
  `}
`

const SecondColumn = styled.div`
  margin-top: 2rem;

  ${media.sm`
    grid-row: 1;
    margin-bottom: 2rem;
    margin-top: 0;
  `}

  ${media.md`
    margin-top: 0;
    margin-bottom: 2rem;
    grid-row: 1;
    ${props =>
      props.relative &&
      `
      display: none;
    `}
  `}

  ${props =>
    props.relative &&
    `
    position: relative;
    height: 100%;
  `}
`

const MobileImage = styled.div`
  ${media.md_up`
    display: none;
  `}
  ${media.sm`
    margin-top: 0;
    grid-row: 1;
  `}

  ${media.md`
    margin-top: 0;
    grid-row: 1;
  `}

  margin-top: 4rem;
`

const OverflowGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: fit-content(24rem);
  position: absolute;
  left: 0;
  margin-top: 0;
  gap: 1rem;
  max-width: 1500px;
  width: 1200px;
  align-items: start;
`

const ExampleScreenshot = styled.div`
  position: relative;
  object-fit: cover;
  overflow: hidden;
  border-radius: 1rem;
  z-index: 1;
  box-shadow: 0px 64px 64px 0px rgb(160 180 200 / 40%),
    0px 32px 32px 0px rgb(160 180 200 / 60%),
    0px 8px 8px 0px rgb(160 180 200 / 80%), 0px 2px 2px 0px #a0b4c8;
`

const ResourceHeader = ({
  name,
  alt,
  subtitle,
  kpi = false,
  image,
  shadow = true,
  links,
  categories,
  ctaCategory,
}) => {
  return (
    <DashboardWrapper shadow={shadow} hasCta>
      <Container>
        <Div margin="2rem 0 4rem" marginSm="2rem 0">
          <Breadcrumb noPadding={true} links={links} />
          <Grid
            alignItems="start"
            gap="4rem"
            margin="0"
            marginMd="2rem 0 0"
            marginSm="2rem 0 0"
            gapMd="0"
            gapSm="0"
            columns={kpi ? "1fr 1fr" : "2fr 3fr"}
            columnsMd="1fr"
            columnsSm="1fr"
          >
            <Column>
              <header>
                <Heading
                  itemProp={"headline"}
                  fontSize="4rem"
                  margin="1rem 0"
                  mobileScale={0.6}
                >
                  {name}
                </Heading>
                <Paragraph
                  fontWeight="600"
                  fontSize="1.3rem"
                  lineHeight="1.5"
                  margin="0 0 2rem"
                  letterSpacing="-0.03rem"
                  mobileScale={0.9}
                >
                  {subtitle}
                </Paragraph>
              </header>
              <SignUpCTA category={ctaCategory} />
            </Column>
            {categories ? (
              <>
                <SecondColumn relative>
                  <OverflowGrid>
                    {categories.map(({ node: category }, i) => {
                      if (i < 6) {
                        return (
                          category.image && (
                            <ExampleScreenshot key={`ExampleScreenshot-${i}`}>
                              <Image
                                file={category.image}
                                alt={alt}
                                eager
                                radius={1}
                                showOverflow
                              />
                            </ExampleScreenshot>
                          )
                        )
                      }
                    })}
                  </OverflowGrid>
                </SecondColumn>
                <MobileImage>
                  <Image radius={1} eager showOverflow file={image} alt={alt} />
                </MobileImage>
              </>
            ) : (
              <SecondColumn>
                <Image radius={1} eager showOverflow file={image} alt={alt} />
              </SecondColumn>
            )}
          </Grid>
        </Div>
      </Container>
    </DashboardWrapper>
  )
}

ResourceHeader.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  kpi: PropTypes.bool,
  image: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
  links: PropTypes.array.isRequired,
  alt: PropTypes.string,
  categories: PropTypes.array,
  ctaCategory: PropTypes.string,
}

export default ResourceHeader
